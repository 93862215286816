import Banner1 from '../assests/Banner1.jpg';
import Banner2 from '../assests/Banner2.jpg';
import Banner3 from '../assests/Banner3.jpg';
import PrivacyPolicy from '../assests/pdfs/Privacy Policy - TTR.pdf';
import PrivacyNoticeForCaliforniaResidents from '../assests/pdfs/PRIVACY NOTICE FOR CALIFORNIA RESIDENTS.pdf';
import NoticeOfRightToOptOutOfSaleOfInformation from '../assests/pdfs/Notice of Right to Opt Out of Sale of Information.pdf';
import FAQ from '../assests/pdfs/Panelist T&C - TTR.pdf'

const CardsData = [
	{
		id: "1",
		imgUrl: Banner1,
		labelText: "Flexible Participation: Surveys from platform(s) you prefer.",
		description: "Whether you're on your morning commute, enjoying your lunch break, or winding down in the evening, you can take surveys from any platform of your choice. Our portal adapts to your schedule, giving you the freedom to participate at your convenience."
	},
	{
		id: "2",
		imgUrl: Banner2,
		labelText: "Earn Rewards: Get paid for sharing your opinions.",
		description: "Your time and opinions hold real value, and we believe you should be rewarded for them. With each completed survey, you'll accumulate reward points that can be converted into cash or used to redeem exciting gift cards from your favorite brands."
	},
	{
		id: "3",
		imgUrl: Banner3,
		labelText: " Unlock Your Potential with Our Online Sample Portal.",
		description: "When you become a part of our community, you're opening the door to a world of benefits that come with participating in online surveys. Your valuable opinions are more than just insights – they're your ticket to a variety of rewards and opportunities."
	},
];

const FooterData = [
	{
		id: "1",
		labelText: "T&C",
		pdfUrl: FAQ,
	},
	{
		id: "2",
		labelText: "Privacy Policy",
		pdfUrl: PrivacyPolicy,
	},
	{
		id: "3",
		labelText: "Privacy Policy-California Residents",
		pdfUrl: PrivacyNoticeForCaliforniaResidents,
	},
	{
		id: "4",
		labelText: "Do Not Sell My Personal Information",
		pdfUrl: NoticeOfRightToOptOutOfSaleOfInformation,
	},
	{
		id: "5",
		labelText: "Contact",
		pdfUrl: "",
		mailto: "support@opinion-matters.com"
	},
];

export { CardsData, FooterData };