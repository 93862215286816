import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, {useEffect } from 'react';

//Components
import Home from './pages/Home';
import { Auth } from "./layouts/auth";
import { Dashboard } from "./layouts/dashboard";


function App() {
  useEffect(() => {
    // Get the API key from the .env file
    console.log('Init App Environment', process.env.NODE_ENV);

  }, []);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;