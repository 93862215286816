import axios from 'axios';
import { ENV } from '../constants/env.const';

const axiosWrapper = axios.create({
  baseURL: ENV.BASE_URL,
});

axiosWrapper.interceptors.request.use(
  (config) => {
    // Do something before the request is sent
    console.log('Request Config', config);
    config.headers['Content-Type'] = 'application/json'
    return config;
  },
  (error) => {
    // Do something if the request fails
    console.log('Request Error', error);
    return error;
  },
);

axiosWrapper.interceptors.response.use(
  (response) => {
    // Do something after the request is successful
    return response;
  },
  (error) => {
    // Do something if the request fails
    return error;
  },
);

export function get(url, params = {}) {
  console.log('called get', url);
  return axiosWrapper.get(url, {
    params,
  });
};

export function post(url, data) {
  return axiosWrapper.post(url, data);
};

export function put(url, data) {
  return axiosWrapper.put(url, data);
};

export function remove(url) {
  return axiosWrapper.delete(url);
};

// const exportObj = {
//     get,
//     post,
//     put,
//     remove,
// }

// export default exportObj;
