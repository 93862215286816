import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { get } from "../../utils/axios";
import { API_URLS } from "../../constants/env.const";

export function DashboardUsers() {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);

  async function fetchUsers() {
    setLoading(true);
    const resp = await get(API_URLS.getAllUsers);
    // fields = resp.status === 200 ? resp.data : {};
    console.log(resp, "resp>>");

    setFields(resp.status === 200 ? resp.data : []);
  }

  useEffect(() => {
    void fetchUsers();
    setLoading(false);
  }, []);

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <Typography variant="h6" color="white">
            Users
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {["First Name", "Last Name", "Email", "Status"].map(
                  (el) => (
                    <th
                      key={el}
                      className="border-b border-blue-gray-50 py-3 px-5 text-left"
                    >
                      <Typography
                        variant="small"
                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                      >
                        {el}
                      </Typography>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {fields.map(
                ({ firstName, lastName, email, state }, key) => {
                  const className = `py-3 px-5 ${key === fields.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                    }`;

                  return (
                    <tr key={email}>
                      <td className={className}>
                        <div className="flex items-center gap-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold"
                          >
                            {firstName}
                          </Typography>
                        </div>
                      </td>

                      <td className={className}>
                        <div className="flex items-center gap-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold"
                          >
                            {lastName}
                          </Typography>
                        </div>
                      </td>

                      <td className={className}>
                        <div className="flex items-center gap-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold"
                          >
                            {email}
                          </Typography>
                        </div>
                      </td>

                      <td className={className}>
                        <div className="flex items-center gap-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold"
                          >
                            {state}
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
  );
}

export default DashboardUsers;
