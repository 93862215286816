const loginFields = [
  {
    label: "Email address",
    key: "email",
    value: "",
    name: "email",
    type: "text",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "Email address"
  },
  {
    label: "Password",
    key: "password",
    value: "",
    name: "password",
    type: "password",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "Password"
  }
]

const signupFields = [
  {
    label: "First Name",
    key: "firstName",
    value: "",
    name: "firstName",
    type: "text",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "First Name"
  },
  {
    label: "Last Name",
    key: "lastName",
    value: "",
    name: "lastName",
    type: "text",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "Last Name"
  },
  {
    label: "Email address",
    key: "email",
    value: "",
    name: "email",
    type: "email",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "Email address"
  },
  {
    label: "Contact",
    key: "contact",
    value: "",
    name: "contact",
    type: "number",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "Contact Number"
  },
  {
    label: "Referral Code",
    key: "refCode",
    value: "",
    name: "refCode",
    type: "text",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "Referral Code"
  },
  {
    label: "Password",
    key: "password",
    value: "",
    name: "password",
    type: "password",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "Password"
  },
  {
    label: "Confirm Password",
    key: "confirmPassword",
    value: "",
    name: "confirmPassword",
    type: "password",
    options: [],
    optionURL: "",
    isRequired: true,
    placeholder: "Confirm Password"
  }
]

export { loginFields, signupFields }