
import { Login } from "./pages/auth/Login";
import { Signup } from "./pages/auth/Signup";

import {
  HomeIcon,
  TableCellsIcon,
} from "@heroicons/react/24/solid";
import { DashboardHome } from "./pages/dashboard/d-home";
import DashboardUsers from "./pages/dashboard/d-users";

const icon = {
  className: "w-5 h-5 text-inherit",
};

export const routes = [
  {
    layout: "dashboard",
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: "dashboard",
        path: "/home",
        element: <DashboardHome />,
      },
      // {
      //   icon: <UserCircleIcon {...icon} />,
      //   name: "profile",
      //   path: "/profile",
      //   element: <Profile />,
      // },
      // {
      //   icon: <TableCellsIcon {...icon} />,
      //   name: "Users",
      //   path: "/users",
      //   element: <DashboardUsers />,
      // },
      // {
      //   icon: <BellIcon {...icon} />,
      //   name: "notifactions",
      //   path: "/notifactions",
      //   element: <Notifications />,
      // },
    ],
  },
  {
    title: "auth pages",
    layout: "auth",
    pages: [
      {
        name: "sign in",
        path: "/login",
        element: <Login />,
      },
      {
        name: "sign up",
        path: "/signup",
        element: <Signup />,
      },
    ],
  },
];

export default routes;
