import { useState, useEffect } from "react";
import { get, post } from "../../utils/axios";
import { API_URLS } from "../../constants/env.const";
import {
	Card,
	CardBody,
	CardFooter,
	Button,
	Typography,
} from "@material-tailwind/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SignupImage from '../../assests/signup-login-banner.png';
import CustomInput from "../../components/CustomInput";
import ToastMessage from "../../components/ToastMessage";

export function Login() {
	const [loginState, setLoginState] = useState({});
	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");
	const [showToastMsg, setShowToastMsg] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const emailVerifyMsg = location?.state?.emailVerifyMsg;

	async function fetchLoginFields() {
		setLoading(true);
		const resp = await get(API_URLS.formLogin);
		setFields(resp.status === 200 ? resp.data : []);
		let fieldsState = {};
		fields.map((field) => (fieldsState[field.key] = ""));
		setLoginState(fieldsState);
	}

	useEffect(() => {
		void fetchLoginFields();
		setLoading(false);
	}, []);

	useEffect(() => {
		setShowToastMsg(true);
	}, [emailVerifyMsg]);

	const handleChange = (name, value) => {
		setLoginState({ ...loginState, [name]: value });
	};

	const handleSubmit = () => {
		post(API_URLS.login, loginState).then(res => {
			if (res.status === 201 && res.data) {
				localStorage.setItem('token', res.data.token)
				navigate(API_URLS.dashboardHome);
			} else {
				setErrorMsg(res.response.data.message);
			}
		})
	};

	const handleVerifyClose = () => {
		setShowToastMsg(false);
	}

	return (
		<div className="py-[20px] flex justify-center w-full">
			<div className="flex flex-row justify-center w-4/5 border-[1px] border-solid border-[#E0E0E0] rounded">
				<Card className="hidden md:block h-full min-h-[570px] w-full max-w-[40rem] shadow-none">
					<img
						className="object- h-full"
						src={SignupImage}
						alt="Hero"
					/>
				</Card>
				<Card className="h-full min-h-[400px] sm:min-h-[570px] w-full max-w-[40rem] shadow-none justify-center">
					<CardBody className="flex flex-col gap-4 sm:px-[2rem] md:px-[4rem] pt-0 pb-[2rem]">
						{showToastMsg && emailVerifyMsg &&
							<ToastMessage emailVerifyMsg={emailVerifyMsg} onClose={handleVerifyClose} />
						}
						<Typography variant="h3" color="black">
							Log In
						</Typography>
						{!loading && fields.map(field =>
							<CustomInput key={field.name} field={field} onChange={handleChange} />
						)}
						{errorMsg &&
							<p className="text-[#F00] text-xs">{errorMsg}</p>
						}
					</CardBody>
					<CardFooter className="p-[1rem] sm:p-[2rem] md:p-[4rem] pt-0 h-[10%] my-[5px]">
						<Button variant="gradient" fullWidth color="orange" onClick={handleSubmit}>
							Log In
						</Button>
						<Typography variant="small" className="mt-6 flex justify-center">
							Don't have an account yet?
							<Link to="/auth/signup">
								<Typography
									as="span"
									variant="small"
									color="orange"
									className="ml-1 font-bold"
								>
									Sign Up
								</Typography>
							</Link>
						</Typography>
					</CardFooter>
				</Card>
			</div>
		</div>
	);
}

export default Login;
