import { Routes, Route } from "react-router-dom";
import routes from "../routes";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";


export function Auth() {

  return (
    <div className="min-h-screen w-full">
      <Header />
      <Routes>
        {routes.map(
          ({ layout, pages }) =>
            layout === "auth" &&
            pages.map(({ path, element }) => (
              <Route exact path={path} element={element} />
            ))
        )}
      </Routes>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
}

Auth.displayName = "/src/layout/Auth.js";

export default Auth;
