import {
    BanknotesIcon,
    UserIcon,
    ChartBarIcon,
    CheckIcon,
  } from "@heroicons/react/24/solid";
  
  export const smallCardsData = [
    {
      color: "blue",
      icon: BanknotesIcon,
      title: "Today's Rewards Earned",
      value: "$8,643",
      footer: {
        color: "text-green-500",
        value: "+25%",
        label: "than last week",
      },
    },
    {
      color: "pink",
      icon: ChartBarIcon,
      title: "Today's Live Survey",
      value: "143",
      footer: {
        color: "text-green-500",
        value: "+3%",
        label: "than last month",
      },
    },
    {
      color: "green",
      icon: CheckIcon,
      title: "Today's Complete Count",
      value: "2,678",
      footer: {
        color: "text-red-500",
        value: "-2%",
        label: "than yesterday",
      },
    },
    {
      color: "orange",
      icon: UserIcon,
      title: "Today's Active User Count",
      value: "21,371",
      footer: {
        color: "text-green-500",
        value: "+5%",
        label: "than yesterday",
      },
    },
  ];
  
  export default smallCardsData;
  