import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import HeroImg from '../assests/hero-image.png';
import Banner4 from '../assests/Banner4.png';
import Cards from '../components/Cards';
import Footer from '../components/Footer';
import { put } from "../utils/axios";
import { API_URLS } from "../constants/env.const";
import {
  Typography,
} from "@material-tailwind/react";

export default function Home() {

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {

    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(location.search);
    const signupParam = queryParams.get('signup');
    const emailVerify = {
      emailVerifyMsg: "Email Verified Successfully."
    };

    if (signupParam) {
      setLoading(true);
      put(API_URLS.mailverified + signupParam, {}).then(res => {
        if (res.status === 200) {
          setLoading(false);
          navigate('auth/' + API_URLS.login, { state: emailVerify });
        } else {
          alert("error at mail verified")
        }
      })

    }
  }, [location.search]);

  return (
    <>
      {!loading &&
        <>
          <Header showAuthBtns={true} />
          <section className="relative h-56 sm:h-72 md:h-80 lg:h-[35rem]">
            <img className="h-full w-full object-cover" src={HeroImg} alt="Hero Image" />
          </section>
          <section className="py-5 sm:py-10 md:py-15 flex flex-col justify-center items-center">
            <div className="py-3 sm:py-5 flex flex-col justify-center items-center">
              <Typography
                variant="h3"
                color={"black"}
                className="text-xl sm:text-2xl font-black"
              >
                Empower Your Voice, Unlock Your Rewards!
              </Typography>
              <Typography
                variant="h5"
                color={"blue-gray"}
                className="font-sans my-4 sm:my-7 text-center w-[70vw] sm:w-[60vw] md:w-full"
              >
                Join our community and discover the power of your opinions – earn rewards, your way!
              </Typography>
            </div>
            <Cards />
          </section>
          <section className="relative bg-cover bg-center h-40 sm:h-72 md:h-80 lg:h-[35rem]">
            <img className="h-full w-full object-cover" src={Banner4} alt="Banner Image" />
          </section>
          <Footer />
        </>
      }
    </>
  );
}