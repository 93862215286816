import { Routes, Route } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { IconButton } from "@material-tailwind/react";
import { Sidebar } from "../components/SideBar";
import { Footer } from "../components/Footer";

import routes from "../routes";
import {
  useMaterialTailwindController,
  MaterialTailwindControllerProvider,
} from "../context/index";

export function Dashboard() {
  return (
    <div className="min-h-screen bg-blue-gray-50/50">
      <MaterialTailwindControllerProvider>
        <Sidebar
          routes={routes.filter((route) => route.layout === "dashboard")}
        />
      </MaterialTailwindControllerProvider>

      <div className="p-4 xl:ml-80">
        {/* <DashboardNavbar /> */}
        {/* <Configurator /> */}
        {/* <IconButton
          size="lg"
          color="white"
          className="fixed bottom-8 right-8 z-40 rounded-full shadow-blue-gray-900/10"
          ripple={false}
          onClick={() => setOpenConfigurator(dispatch, true)}
        >
          <Cog6ToothIcon className="h-5 w-5" />
        </IconButton> */}
        <Routes>
          {routes.map(
            ({ layout, pages }) =>
              layout === "dashboard" &&
              pages.map(({ path, element }) => (
                <Route exact path={path} element={element} />
              ))
          )}
        </Routes>
      </div>
      {/* <div className="text-blue-gray-600">
        <Footer />
      </div> */}
    </div>
  );
}

Dashboard.displayName = "/src/layout/dashboard.js";

export default Dashboard;
