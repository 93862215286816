import { CardsData } from '../mock-data/data';

const Cards = () => {
  return (
    <div className='flex flex-col md:flex-row '>
      {CardsData.map((card => {
        const { id, imgUrl, labelText, description } = card;
        return (
          <div key={id} className="min-w-[10rem] max-w-sm rounded shadow-lg sm:mr-5 mb-4 md:mb-[2rem] flex-wrap">
            <img className="w-full h-[18rem]" src={imgUrl} alt="Sunset in the mountains" />
            <div className="px-6 pt-4 pb-10">
              <div className="font-bold text-xl mb-4">{labelText}</div>
              <p className="text-gray-700 text-base">
                {description}
              </p>
            </div>
          </div>
        )
      }))}
    </div>
  );
}
export default Cards;