// CustomInput.js
import React from 'react';
import { Input } from '@material-tailwind/react';

const CustomInput = ({ field, onChange }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  return (
    <div className="my-[5px]">
      <Input
        variant="standard"
        color="orange"
        type={field.key}
        label={field.label}
        size="md"
        name={field.name}
        onChange={handleChange}
      />
    </div>
  );
};

export default CustomInput;
