import logo from '../assests/Logo-updated.png';
import { Link } from 'react-router-dom';

export function Header({ showAuthBtns = false }) {
  return (
    <header className="sm:w-full pl-0 md:pr-[10px] sm:px-10 sm:py-10 flex flex-row h-[80px] sm:h-[100px] items-center justify-between">
      <div className="sm:w-[200px] scale-[50%] sm:scale-[60%] lg:scale-[80%] sm:ml-[10px] lg:ml-[20px]">
        <Link to="/">
          <img src={logo} alt="Company logo" />
        </Link>
      </div>
      {showAuthBtns && <div className="flex flex-end">
        <Link to="/auth/signup" className='min-w-max bg-red-500 hover:bg-red-500 hover:cursor-pointer text-white font-semibold py-2 px-5 sm:py-3 sm:px-9 mr-2 self-center rounded focus:outline-none focus:shadow-outline'>
          Join Now
        </Link>
        <Link to='/auth/login' className='bg-red-500 hover:bg-red-500 hover:cursor-pointer text-white font-semibold py-2 px-5 sm:py-3 sm:px-9 rounded self-center focus:outline-none focus:shadow-outline'>
          Login
        </Link>
      </div>}
    </header>
  );
}

export default Header;