import { useState, useEffect } from "react";
import { signupFields } from "../../constants/formFields";
import { get, post } from "../../utils/axios";
import { API_URLS } from "../../constants/env.const";

import CustomInput from "../../components/CustomInput";
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import SignupImage from '../../assests/signup-login-banner.png';

const fields = signupFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.key] = ""));

const defaultError = {
  type: 'error',
  message: '',
  enable: false
}

export function Signup() {
  const [signUpState, setSignUpState] = useState({});
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [errorMsg, setErrorMsg] = useState(defaultError);

  async function fetchLoginFields() {
    setLoading(true);
    const resp = await get(API_URLS.formSignup);
    setFields(resp.status === 200 ? resp.data : []);
    let fieldsState = {};
    fields.map((field) => (fieldsState[field.key] = ""));
    setSignUpState(fieldsState);
  }

  useEffect(() => {
    void fetchLoginFields();
    setLoading(false);
    setTimeout(() => {
      setErrorMsg(defaultError);
    }, 5000);
  }, []);

  const handleChange = (key, value) => {
    setSignUpState({ ...signUpState, [key]: value });
  };

  const handleSubmit = () => {
    post(API_URLS.signup, signUpState).then(res => {

      if (res.status === 201) {
        if (res.data.message) {
          setErrorMsg({
            type: res.data.status,
            message: res.data.message,
            enable: true
          });
        }
      } else {
        setErrorMsg({
          type: 'error',
          message: res.response.data.message,
          enable: true,
        });
      }
    })
  };

  return (
    <div className="sm:py-[10px] sm:px-[10px] sm:py-[20px] sm:px-[20px] flex flex-row justify-center w-full">
      <div className="flex flex-row justify-center w-[85%] sm:w-4/5 border-[1px] border-solid border-[#E0E0E0] rounded">
        <Card className="hidden lg:block h-full min-h-[570px] w-full max-w-[40rem] shadow-none">
          <img
            className="object- h-full"
            src={SignupImage}
            alt="Hero"
          />
        </Card>
        <Card className="h-full min-h-[570px] w-full max-w-[40rem] shadow-none justify-center">
          <CardBody className="flex flex-col gap-4 sm:px-[2rem] md:px-[4rem] pt-0 pb-[2rem]">
            <Typography variant="h3" color="black">
              Sign Up
            </Typography>
            {!loading && fields.map((field) =>
              <CustomInput key={field.name} field={field} onChange={handleChange} />
            )}
            {errorMsg.enable &&
              <p className={
                errorMsg.type === 'error' ? "text-xs text-red-500" : "text-xs text-blue-500"
              }>{errorMsg.message}</p>
            }
          </CardBody>
          <CardFooter className="p-[1rem] sm:p-[2rem] md:p-[4rem] pt-0 h-[10%] my-[5px]">
            <Button variant="gradient" fullWidth color="orange" onClick={handleSubmit}>
              Sign Up
            </Button>
            <Typography variant="small" className="mt-6 flex justify-center">
              Already have an account?
              <Link to="/auth/login">
                <Typography
                  as="span"
                  variant="small"
                  color="orange"
                  className="ml-1 font-bold"
                >
                  Login
                </Typography>
              </Link>
            </Typography>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}

export default Signup;
